import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';

const ChooseContent = () => {
  const { t } = useTranslation();

  const dataList = [
    {
      title: t('Personalized Emergency Guide'),
      desc: t('Tailored to your specific needs and environment.'),
    },
    {
      title: t('Comprehensive Coverage'),
      desc: t('Detailed preparation for over 10 emergency scenarios.'),
    },
    {
      title: t('Lifetime Updates'),
      desc: t('One-time purchase with continuous updates and editing capabilities.'),
    },
    {
      title: t('Peace of Mind'),
      desc: t('Be ready for any crisis with a reliable Plan B.'),
    },
  ];

  return (
    <div className={styles.content}>
      <h2>
        <span>{t('Benefits')} </span>
        <span>{t('to use Planbowl')}</span>
      </h2>

      {/* <BaseLinearContent> */}
      <div className={styles.container}>
        {dataList.map((item, index) => {
          return (
            <div className={styles.cardContainer} key={index + item.title}>
              <img
                src={`/images/planbowl/home-benefits-${index}.webp`}
                alt={'icon'}
                className={styles.icon}
              />

              <p className={classNames(styles.title)}>{item.title}</p>
              <p className={styles.desc} dangerouslySetInnerHTML={{ __html: item.desc }} />
            </div>
          );
        })}
      </div>
      {/* </BaseLinearContent> */}
    </div>
  );
};

export default ChooseContent;
